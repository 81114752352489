/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/angular-calendar/css/angular-calendar.css";

body {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;	
}

hr {
    background-color: #F1F1F1;
    height: 3px;
    margin: 3rem 0rem;
    border: none;
}

button {
	color: #FFFFFF;
	font-weight: 600 !important;
	font-size: 15px !important;
	border: none;
	border-radius: 5px;
	background-color: #1999C7;	 
	outline: none;
	box-shadow: none;
	display: inline-block;
	padding-top: 15px;
	padding-bottom: 19px;
}
	
button:hover {
	background-color: rgba(23, 118, 159, 1) !important;
	cursor: pointer;
}

.screen-container {
	width: 726px;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;		
}

.logins {
	display: flex;
	flex-direction: column;
}

.logins-sub-header {
	display: flex;
	
	align-items: center;
	font-size: 22px;
	height: 237px;
	font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: #FFFFFF;
}

.card-body {
	padding: 0.75rem;
}


@media (min-width: 810px) {
	.card {
		width: 790px !important;
	}
}


@media (min-width: 576px) {
	h6 {				
		line-height: 28px !important;		
	}

	.card-body {
		padding: 1.25rem;
	}
}

.card-header {
	background-color: #FFFFFF !important;
	color: #333333 !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	font-family: 'acumin-pro', sans-serif !important;
	margin: 3px 0 0 !important;
}

.form-check-label {    
	margin-top: 3px !important;
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #555555 !important;
}

.section-header {
	font-size: 18px;
	font-family: 'acumin-pro';
    font-weight: 400;
	font-style: normal;
	color: #333333;
	margin-right: 6px;
}

.section-label {
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #555555 !important;
	margin-bottom: 0.6rem !important; 
}

.section-hint {
	font-size: 13px; 
	color:#36424A;
}

.label-hint {
	font-size: 13px; 
	color:#36424A;
	font-weight: 400;
}

label {
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: #555555 !important;
	margin-bottom: 0.6rem !important; 
}

input::placeholder {
	font-size: 14px !important;
	color: rgba(85, 85, 85, 0.7) !important;
}

input[type=text], input[type=date], input[type=time] {
	//border-color: rgba(102, 102, 102, 1) !important;
    border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
	font-style: normal !important;
	font-size: 13px !important;	
	padding: 3px 2px 3px 10px !important;
	height: 42px !important;
}

select {
	//border-color: rgba(102, 102, 102, 1) !important;
    border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
	font-style: normal !important;
	font-size: 13px !important;   
	color: #666666 !important;
	padding: 3px 2px 3px 10px !important;
	height: 42px !important;
}

.button-secondary {
	font-family: 'acumin-pro';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #0070C4 !important;
    padding: 11px 13px;
    border-radius: 4px;
    border: 2px solid #0070C4;
	background-color: #FFFFFF;
	text-decoration: none !important;
	outline: none !important;
	text-align: center;	
}

.button-secondary:hover {
	background-color: #F2F2F2;
}

.button-primary {
	background-color: #0070C4  !important;    
	border-radius: 4px;
	font-weight: 700;
    font-style: normal;
    font-size: 16px;
	color: #FAFAFA !important;
	text-decoration: none !important;
	padding: 12px 13px;
	outline: none !important;
	text-align: center;	
}

.button-primary:hover {
	background-color: #004F84 !important;	
}

.button-secondary-outline {
	font-family: 'acumin-pro';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #0070C4 !important;
    padding: 8px 8px;
    border-radius: 4px;
    border: 2px solid #0070C4;
	background-color: #FFFFFF;
	text-decoration: none !important;
	outline: none !important;	
}

.button-secondary-outline:hover {
	background-color: #F2F2F2;
}

.custom-control-label {
	outline: none;
}

.custom-control-label::before {    
    top: 0rem !important;    
}

.custom-control-label::after {    
    top: 0rem !important;    
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #0070C4 !important;
    background-color: #0070C4 !important;
}

.required:after {
    content:" (Required)";
	font-size: 13px; 
	color:#36424A;
  }
 
.callout
{
	background-color: whitesmoke;
}  

.callout .callout-logo
{
	background-color: rgba(252, 17, 33, 1);
	display: flex;
	justify-content: center;
	border-radius: 2px;
}

.error {
	color: red;
}

.form-control.is-invalid{
    background-image: none;
}

.callout .callout-logo-info
{
	background-color: #0070C4;
	display: flex;
	justify-content: center;
	border-radius: 2px;
} 

.youth-flag {
    color: #FFFFFF !important;
    border-radius: 12px;
    background-color: rgba(143, 63, 206, 1);
    height: 23px;
    font-size: 14px !important;
    margin: 9px 0 0 26px;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;    
    justify-content: center;    
    padding: 3px 12px 0;
}

.legend {
	margin-top: 20px;
  
	span {
	  border-radius: 5px;
	  margin-right: 20px;
	  min-width: 180px;
	  min-height: 35px;
	  padding: 6px 10px;
	}
  
	.irs-review-written {
	  border: 1px solid #3f7784;
	  background-color: #47ded9;
	}
  
	.irs-review-oral {
	  border: 1px solid #f05c2c;
	  background-color: #ffac42;
	}
  
	.seizure-review {
	  border: 1px solid #ad2121;
	  background-color: #FAE3E3;
	}
  
	.admintime-event {
	  border: 1px solid #1e90ff;
	  background-color: #D1E8FF;
	}

	.unavailable-event {
		border: 1px solid #e3bc08;
		background-color: #FDF1BA;
	}
}

.data-delete-button {
    background-image: url("/assets/images/trash-red.svg");    
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    cursor: pointer;
}

.data-edit-button {
    background-image: url("/assets/images/pencil-square.svg");    
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    cursor: pointer;
}

.data-edit-ok-button {
    background-image: url("/assets/images/check-square-fill.svg");  
	background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    cursor: pointer;
}

.data-edit-cancel-button {
    background-image: url("/assets/images/x-square.svg");    
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    cursor: pointer;
}

.cal-week-view .cal-event {
    color: #000 !important;
    font-size: 16px !important; 
}

.bold-text {
    font-weight: bold !important;
}

.app-cal-event {
	z-index: 9 !important;
}  

.log-flag {
    color: #FFFFFF !important;
    border-radius: 12px;
    width: 50px;
    height: 20px;
    margin-left: 5px;
    text-transform: uppercase;
    display: flex;    
    justify-content: center;    
    background-color: rgba(228, 75, 77, 1);
}

.btn-block {
    width: 100% !important;
}

a {
	text-decoration: none !important;
}

.btn-sm {
	padding: .25rem .5rem !important;
    font-size: .875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
}

.col-6 {
	position: relative;
    padding-right: 15px;
    padding-left: 15px;
}